<script setup>
import { ref } from 'vue';
import { Chat } from "@chat-ui/vue3";
import axios from 'axios'; // Import axios

const chatMessages = ref([
    { message: "Hi there! I'm Dan's personal assistant bot. If you're looking to learn more about Dan's professional background or would like to set up a meeting, you're in the right place. How can I help?", type: 'chatbot', timestamp: currentTimestamp() },
    { message: "Hallo! Ich bin der persönliche Assistenten-Bot von Dan. Wenn Sie mehr über den beruflichen Werdegang von Dan erfahren möchten oder ein meeting vereinbaren möchten, sind Sie hier genau richtig. Wie kann ich Ihnen helfen?", type: 'chatbot', timestamp: currentTimestamp() },
]);
const lastRecursed = ref(false);  // New flag
const recursed = ref(false);      // New flag
function currentTimestamp() {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
}

function handleMessage(message) {
    if (!lastRecursed.value) {
        chatMessages.value.push({
        message: message,
        type: 'person',
        timestamp: currentTimestamp()
    });
    }
    

    const typingMessage = {
        message: '.....',
        type: 'chatbot',
        timestamp: currentTimestamp()
    };

    chatMessages.value.push(typingMessage);

    let animationCycle = 0;
    const animationInterval = setInterval(() => {
        typingMessage.message = '.'.repeat((animationCycle % 5) + 1) + ' '.repeat(5 - (animationCycle % 5));
        chatMessages.value[chatMessages.value.length - 1] = { ...typingMessage };
        animationCycle++;
    }, 500);
    const messagesToSend = chatMessages.value.filter(message => message.message !== '.....' && !message.message.includes('.... '));

    // Send the message to the server
    axios.post('https://metafitx.com/send_message', { messages: messagesToSend,
        last_recursed: lastRecursed.value, 
        recursed: recursed.value  })
        .then(response => {
            console.log('Response received:', response);
            clearInterval(animationInterval); // Stop the typing animation
            chatMessages.value.pop(); // Remove the "typing" message

            // Append the bot's reply received from the server
            const botReply = {
                message: response.data.messages,
                type: 'chatbot',
                timestamp: currentTimestamp()
            };
            chatMessages.value.push(botReply);
            if (response.data.recurse) {
            lastRecursed.value = true;
            recursed.value = true;
            handleMessage();  // Recursively send another message
        }else{
            if (lastRecursed.value) {
                lastRecursed.value = false;
            }
        }


        })
        .catch(error => {
            console.error('Error sending message:', error);
            // Handle the error, maybe notify the user or retry the request
        });
}
</script>


<template>
    <Chat :chat="chatMessages" :onSend="handleMessage" textColorMessageTimestamp="white" bgColorMessageTimestamp="#1a1a1a" bgColorMessagePerson="#576CBC" textColorInput="white" bgColorMessageChatbot="#5C8374"  bgColorChat="#040D12" bgColorHeader="#183D3D" bgColorInput="#183D3D" bgColorIcon="#5C8374" textColorMessageChatbot="white" textColorMessagePerson="white"/>
</template>




  

