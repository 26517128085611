<template>
    <div class="card">
      <div class="card-content">
        <a :href="link" target="_blank" class="title">{{ title }}</a>
      <img :src="qrCode" alt="QR code to download MetaFitX app" class="qr-code">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      link: String,
      qrCode: String
    }
  }
  </script>
  
  <style scoped>
  .card {
    width: 250px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px;
    overflow: hidden;
  }
  
  .card-content {
    padding: 20px;
    text-align: center;
    
  }
  
  .title {
  font-size: 18px;
  color: #64ffda; /* Brighter blue color that contrasts well with dark backgrounds */
  text-decoration: none; /* By default, the link will not be underlined */
  font-weight: bold; /* Make the link bold */
  padding: 5px 0; /* Some padding for better spacing */
  display: inline-block; /* Required for adding padding */
  margin-bottom: 15px;
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.title:hover {
  color: #59825d; /* Lighter color on hover */
  text-decoration: underline; /* Underline on hover */
}
  
  .qr-code {
    width: 200px;
    height: 200px;
    border-radius: 15px; 
  }
  </style>
  