
import { Vue, Options } from 'vue-class-component';
import chatbot from './components/ChatBot.vue';
import AnimatedTitle from './components/AnimatedTitle.vue';
import AppCard from './components/QrCodes.vue';

@Options({
  components: {
    AnimatedTitle,
    AppCard,
    chatbot
  },
})
export default class App extends Vue {

  data() {
    return {
      showText: true,
      x: ""
    };
  }
}
